import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Flickity from "react-flickity-component";

import Layout from "../../layout/Layout";
import OfferGuide from "../../components/offer-guide/OfferGuide";

import collectionStyle from "./assets/Collection.module.scss";
import "../../styles/flickity.scss";

const CollectionTemplatePage = ({ data }) => {
  const collectionName = data.django.allCollections.edges[0].node.name;
  const collectionDescription =
    data.django.allCollections.edges[0].node.description;
  const collectionItems =
    data.django.allCollections.edges[0].node.dressSet.edges;

  const collectionSliderOptions = {
    cellAlign: "center",
    prevNextButtons: false,
    pageDots: false
  };

  return (
    <Layout title={collectionName}>
      <section name="collection" className={collectionStyle["collection"]}>
        <div className={collectionStyle["collection_container"]}>
          <div className={collectionStyle["collection_title"]}>
            <h1>{collectionName}</h1>
          </div>
          {collectionDescription && (
            <div className={collectionStyle["collection_description"]}>
              <p>{collectionDescription}</p>
            </div>
          )}
          <div className={collectionStyle["collection_list"]}>
            {collectionItems.map(item => (
              <Flickity
                key={item.node.id}
                className={collectionStyle["collection_list_item"]}
                options={collectionSliderOptions}
              >
                {item.node.dresspictureSet.edges.map(image => (
                  <div
                    key={image.node.id}
                    className={collectionStyle["collection_list_item_image"]}
                  >
                    <Img
                      fluid={
                        image.node.processedPhotoSharp.childImageSharp.fluid
                      }
                      alt=""
                    />
                  </div>
                ))}
              </Flickity>
            ))}
          </div>
        </div>
      </section>

      <OfferGuide />
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    django {
      allCollections(slug: $slug) {
        edges {
          node {
            name
            note
            dressSet {
              edges {
                node {
                  id
                  name
                  note
                  dresspictureSet {
                    edges {
                      node {
                        id
                        processedPhoto
                        processedPhotoSharp {
                          childImageSharp {
                            fluid {
                              ...GatsbyImageSharpFluid
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

CollectionTemplatePage.propTypes = {
  data: PropTypes.object.isRequired
};

export default CollectionTemplatePage;
